
import { mapState, mapMutations } from 'vuex'
export default {
  data() {
    return {
      drawer: false,
      group: null,
    }
  },
  watch: {
    isMobileMenuOpen() {
      this.drawer = this.isMobileMenuOpen
    },
    group() {
      this.closeDrawer()
    },
  },
  created() {
    console.log('this.logo', this.logo)
  },
  computed: {
    ...mapState('config/menu', [
      'isMobileMenuOpen',
      'navMenus',
      'logo',
      'homeUrl',
      'showManagementLogin',
    ]),
  },
  methods: {
    ...mapMutations('config/site', ['setScheduleShow', 'setLogInDialogShow']),
    ...mapMutations('config/menu', ['setMobileMenuClick']),

    update(ev) {
      console.log(ev)
      this.setMobileMenuClick()
    },
    closeDrawer() {
      this.drawer = false
    },
    scheduleShow() {
      this.setScheduleShow({ demo: true, visit: false })
    },
    logInDialogShow() {
      this.setLogInDialogShow(true)
    },
  },
}
