
export default {
  data() {
    return {
      selectedLang: 'th',
    }
  },
  fetch() {
    this.selectedLang = this.$i18n.locale
    this.$forceUpdate()
  },
  watch: {
    '$i18n.locale'() {
      console.log(this.$i18n.locale)
      this.selectedLang = this.$i18n.locale
      this.$forceUpdate()
    }
  },
  methods: {
    switchLang(ev) {
      console.log(ev)
      this.selectedLang = ev
      this.$forceUpdate()
      this.$i18n.setLocale(ev)
    },
  },
}
