
import { mapActions } from 'vuex'
export default {
  props:{
    role:{
      type:Number,
      default:2
    },
    isThaiRathUser:{
      type:Boolean,
      default:false
    },
    selectedPriceOption:{
      type:Number,
      default:0
    },
    emailToCheck:{
      type:String,
      default:null
    }
  },
  data() {
    return {
      showthankyoupopup: false,
      valid: false,
      show: false,
      step: 1,
      userObj: {
        email: null,
        password: null,
        firstName: null,
        lastName: null,
        phoneNumber: null,
        role: this.role
      },
      checkEmailFound:null,
      registerButtonClicked: false,
      firstNameRules: [v => !!v || this.$t('First Name is required')],
      lastNameRules: [v => !!v || this.$t('Last Name is required')],
      emailRules: [
        v => !!v || this.$t('E-mail is required'),
        v => /.+@.+\..+/.test(v) || this.$t('E-mail must be valid'),
      ],
      passwordRules: [
        v => !!v || this.$t('Password is required'),
        v =>
          (v && v.length >= 8) ||
          this.$t('Password must be equal or greater than 8 characters'),
      ],
      phoneRules: [
        v => !!v || this.$t('Phone Number is required'),
        v => {
          if (v && v.length) {
            return (
              v[v.length - 1].length <= 10 ||
              this.$t('Phone number should not be longer than 10 characters')
            )
          } else return true
        },
      ],
    }
  },
  mounted(){
      console.log("this.emailToCheck",this.emailToCheck)
      if(this.emailToCheck){
        this.userObj.email=this.emailToCheck
        this.checkEmailInDb()
      }
  },
  watch:{
    emailToCheck(){
      console.log("this.userObj.email",this.userObj.email)
      if(this.emailToCheck){
        this.userObj.email=this.emailToCheck
        
      }
    }
  },
  methods: {
    ...mapActions('authentication', ['register','chosePakcage','checkThairathUser']),
    ...mapActions('config/site', ['setErrorSnackbar']),
    async checkEmailInDb(){
      console.log("this.checkEmailFound")
      const res = await this.checkThairathUser({email:this.userObj.email})
      if(res){
        this.checkEmailFound=res.data
        console.log("this.checkEmailFound",this.checkEmailFound)
        // if(res.data.thairathUser){
        //   this.userObj.role=8
        // }
      }
    },
    // register
    hideRegister() {
      this.$emit('hideRegister', false)
    },
    validate() {
      return this.$refs.registerForm.validate()
    },
    clickRegister() {
      this.registerButtonClicked = true
      if (this.validate()) {
        this.register({ userInfo: this.userObj })
          .then(data => {
            if (data) {
              if(this.isThaiRathUser){
                this.showthankyoupopup=true
              }
              this.setErrorSnackbar({
                title: this.$t('Register!'),
                msg: this.$t('Successful!'),
                color: this.$vuetify.theme.themes.light.success,
              })
              this.step = 3
            } else {
              this.$refs.registerForm.reset()
              this.validate()
              this.setErrorSnackbar({
                title: this.$t('Register!'),
                msg: this.$t('Failed!, Please Check form!'),
                color: this.$vuetify.theme.themes.light.error,
              })
            }
            if(!this.isThaiRathUser){
              this.sendPackage()
            }
            setTimeout(() => {
              this.registerButtonClicked = false
            }, 500)
          })
          .catch(err => {
            console.log(err)

            this.registerButtonClicked = false

            this.valid = false
            if (err&&err.response.data.message === 'Email is already used') {
              this.step = 1
              this.userObj.email = null
              this.validate()
            }
            this.setErrorSnackbar({
              title: this.$t('Register!'),
              msg: this.$t(err.response.data.message),
              color: this.$vuetify.theme.themes.light.error,
            })
          })
      } else {
        this.registerButtonClicked = false
      }
    },
    sendPackage(){
      const id= JSON.parse(localStorage.getItem('selectedPackage')).id
      const addons= localStorage.getItem('selectedAddons')
      this.chosePakcage({
        "email": this.userObj.email,
        "packageId": id,
        "addOns": addons?addons:null,
        "selectedPriceOption":this.selectedPriceOption
      }).then(data=>{
        console.log("sendPackage",data)
      })
    },  
    mockup() {
      this.userObj = {
        email: 'test' + Math.floor(Math.random() * 10) + '@Properdee.com',
        password: '123456789',
        firstName: 'test' + Math.floor(Math.random() * 10),
        lastName: 'test' + Math.floor(Math.random() * 10),
        phoneNumber: '123456789',
      }
    },
  },
  destroyed() {
    console.log('register destroyed!!')
    this.hideRegister()
  },
}
