const actions = {
  
 async addDemo({ commit }, params) {
    const response = await this.$repositories.demo.postDemo(params)
    
    return response.data
  },

}
export default actions
