const controller = '/ai'
export default axios => ({
  getPropertyDescriptionFromAi(obj) {
    return axios.post(`${controller}/properties-description`,obj)
  },
  getPropertyValuation(obj){
    return axios.post(`${controller}/properties-valuation`,obj)
  },
  getFollowUpAnswer(obj){
    return axios.get(`${controller}/properties-question?questionId=${obj.id}&propertyId=${obj.propertyId}`,obj)
  },
  getSummaryAiValuation(obj){
    return axios.get(`${controller}/properties-estimated_value?propertyId=${obj.propertyId}`,obj)
  },
  shareValuation(obj){
    return axios.post(`${controller}/properties-estimated_value/share`,obj)
  },
  previewShare(obj){
    return axios.post(`${controller}/properties-estimated_value/preview`,obj)
  },
  getTranslationForAi(obj){
    return axios.get(`${controller}/properties-valuation/translate?propertyId=${obj.propertyId}`,obj)
  },
  getEmailForAi(obj){
    return axios.get(`${controller}/properties-estimated_value/share/emails?propertyId=${obj.propertyId}`,obj)
  },
  updatePropertyValuation(obj){
    return axios.put(`${controller}/properties-valuation?propertyId=${obj.propertyId}`,obj)
  }
})
