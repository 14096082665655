
import {
    mapActions
} from 'vuex'
export default {
    model: {
        prop: 'dialog',
    },
    props: {
        dialog: {
            type: Boolean,
            default: false,
        },
        rentOrBuy: {
            type: Number,
            default: 161
        }
    },
    data() {
        return {
            searchInquiryDiv: false,
            inquiryStep: 0,
            step: 0,
            filterDateForInquiry: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            pages: 1,
            pageNumber: 1,
            gridOrList: 1,
            itemsPerPage: 3,
            dateListOfInquiries: [],
        }
    },
    watch: {
        dialog() {
            this.inquiryStep = 0
            this.searchInquiryDiv = false
        },
        inquiryStep() {
            if (this.inquiryStep === 1) {
                this.listOfInquiryDates()
            }
        }
    },
    methods: {
        ...mapActions('manager', ['getInquiryDates', 'clearAllInquiryDates']),
        showSearch(ev) {
            this.searchInquiryDiv = ev
        },
        dateChangeEvent(ev) {
            this.filterDateForInquiry = ev
        },
        changeGridView(ev) {
            this.gridOrList = ev
        },
        eventDatesFncForInquiry(ev) {
            let taskColor = null
            this.dateListOfInquiries.forEach(el => {
                console.log("ev eventDatesFncForInquiry==>>>>>>", el.date === ev)
                if (el.date === ev) {

                    taskColor = 'blue '
                }
            })
            return taskColor
        },
        listOfInquiryDates() {
            this.getInquiryDates().then(data => {
                this.dateListOfInquiries = data.data
            })
        },
        closeDialog() {
            console.log('close dialog')
            this.$emit('input', false)
            this.$emit('dialog', false)
            this.inquiryStep = 0
            this.searchInquiryDiv = false
        },
    }
}
