/* eslint-disable vue/one-component-per-file */
import Vue from 'vue'
// import { Line, Pie } from 'vue-chartjs'

// Vue.component('LinChart', {
//   extends: Line,
//   props: ['data', 'options'],
//   mounted() {
//     this.renderChart(this.data, this.options)
//   },
// })

// Vue.component('PieChart', {
//   extends: Pie,
//   props: ['data', 'options'],
//   mounted() {
//     this.renderChart(this.data, this.options)
//   },
// })

import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

Vue.component('Chart', VueApexCharts)