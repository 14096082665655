
export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Object,
      default: null,
    },
    errorOptions: {
      type: Object,
      default: null,
    },
    redirect: {
      type: Boolean,
      default: true,
    },
    updatedMethod: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      title: null,
      text: this.$t('Something went wrong!'),
      textSecondLine: null,
      btnText: this.$t('close'),
      event: null,
    }
  },
  watch: {
    error: {
      deep: true,
      handler() {
        console.log('this.error', this.error)
        const data = this.error.data
        // ROOM NOT AVAILABLE
        if (data.code === '101') {
          this.title = this.$t('Sorry!')
          this.text = `this.$t(
            'You are not Admin !'
          )`
          this.textSecondLine = `${this.$t('Try Again !')} `
          this.btnText = this.$t('Close')
          // this.event = 'searchNewRoom'
          // PRICE CHANGED
        } else if (data.code === '102') {
          this.title = this.$t('Sorry!')
          this.text = this.$t('You are not Manager !')
          this.textSecondLine = `${this.$t('Try Again !')} `
          this.btnText = this.$t('Close')
          // ALREADY PAID
        } else if (data.code === '103') {
          this.title = this.$t('error.Sorry!')
          this.text = this.$t('You are not Owner !')
          this.textSecondLine = `${this.$t('Try Again !')} `
          this.btnText = this.$t('Close')
          // FAILED PAYMENT
        } else if (data.code === '104') {
          this.title = this.$t('Sorry!')
          this.text = this.$t('You are not Tenant !')
          this.textSecondLine = `${this.$t('Try Again !')} `
          this.btnText = this.$t('Close')
          // DEFAULT ERROR
        } else if (data.code === '105') {
          this.title = this.$t('Sorry!')
          this.text = this.$t('You are not Vendor !')
          this.textSecondLine = `${this.$t('Try Again !')} `
          this.btnText = this.$t('Close')
        } else if (data.code === '106') {
          this.title = this.$t('Sorry!')
          this.text = this.$t('You are not Agent !')
          this.textSecondLine = `${this.$t('Try Again !')} `
          this.btnText = this.$t('Close')
        } else if (data.code === '107') {
          this.title = this.$t('Sorry!')
          this.text = this.$t('You are not Others !')
          this.textSecondLine = `${this.$t('Try Again !')} `
          this.btnText = this.$t('Close')
        } else {
          // TODO change it to generic 400 error msg
          this.text = this.error.data.message
            ? this.error.data.message
            : this.$t('Something went wrong!')
          this.btnText = this.$t('close')
        }
      },
    },
    errorOptions: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val) {
          this.title = val.title
          this.text = val.text
          this.textSecondLine = val.textSecondLine
          this.btnText = val.btnText
          this.event = val.event
        }
      },
    },
  },
  mounted() {
    console.log('this.error', this.error)
  },
  methods: {
    handleClose() {
      this.$emit('closeError')
      if (this.event) {
        this.$emit(this.event, this.updatedMethod)
      }
      if (this.redirect && this.event === 'searchNewRoom') {
        const data = this.error.data
        this.$router.push(
          this.localeRoute({
            path: `/search/${data.hotelId}`,
            query: {
              checkInDate: data.checkInDate,
              checkOutDate: data.checkOutDate,
              rooms: data.numberRooms,
              guests: data.numberGuests,
              // TODO not there
              locationName: data.locationName,
            },
          })
        )
      } else if (this.redirect && this.event === 'returnHome') {
        this.$router.push(this.localeRoute('/'))
      }
    },
  },
}
