
import { mapMutations, mapState } from 'vuex'
export default {
  data() {
    return {}
  },
  computed: {
    ...mapState('config/menu', ['logo', 'homeUrl']),
  },
  methods: {
    ...mapMutations('config/menu', ['setMobileMenuClick']),
    openMenu() {
      console.log('openMenu')
      this.setMobileMenuClick(true)
    },
  },
}
