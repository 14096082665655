
export default {
  props:{
    color:{
      type:String,
      default:'primary'
    }
  },
  data() {
    return {
      menu: false,
      id: this.$uuid.v4(),
    }
  },
  beforeDestroy() {
    this.$refs[this.id].activatorNode = null
  },
}
