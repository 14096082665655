const controller = '/chat'
export default axios => ({
  getUserForChat(obj) {
    let parmas = `?`
    if (obj.type) {
      parmas = parmas + `&type=${obj.type}`
    }
    if (obj.search) {
      parmas = parmas + `&search=${obj.search}`
    }
    return axios.get(`${controller}/get-users${parmas}`, obj)
  },
  sendMessage(obj) {
    return axios.post(`${controller}/send-message`, obj)
  },
  getListOfChat(obj) {
    return axios.get(`${controller}/chat-history?page=${obj.page}&itemsPerPage=${obj.itemsPerPage}`, obj)
  },
  getChatUserInfo(obj) {
    return axios.get(`${controller}/get-user-info/${obj.chatId}`, obj)
  },
  getChat(obj) {
    return axios.get(`${controller}/get-message/${obj.chatId}?page=${obj.page}`, obj)
  },
  createChat(obj) {
    return axios.post(`${controller}/create-chatId`, obj)
  },
})