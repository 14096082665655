const actions = {

  async getPackages({ commit }) {
    const res = await this.$repositories.getPackages.getPackages()
    if (res.data.data) {
      commit('setPackages', res.data.data)
    }
    return res.data.data
  },

}
export default actions
