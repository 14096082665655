const actions = {
  async getTaxonomy({ commit }) {
    const res = await this.$repositories.getTaxonomy.getTaxonomy()
    if (res.data.data) {
      commit('setTaxonomy', res.data.data)
    }
    return res.data.data
  },
  async getPublicVideos({ commit }, params) {
    const res = await this.$repositories.getTaxonomy.getPublicVideos(params)

    return res.data
  },
}
export default actions
