const actions = {
  async postToPartnersWebsites({ commit }, params) {
    const res = await this.$repositories.manager.postToPartnersWebsites(params)

    return res.data.data
  },
  async addProperty({ commit }, params) {
    const res = await this.$repositories.manager.addProperty(params)

    return res.data.data
  },
  async getAllProperties({ commit }, params) {
    const res = await this.$repositories.manager.getAllProperties(params)

    return res.data
  },
  async getPropertyById({ commit }, params) {
    const res = await this.$repositories.manager.getPropertyById(params)

    return res.data.data
  },
  async getPropertyByIdForEdit({ commit }, params) {
    const res = await this.$repositories.manager.getPropertyByIdForEdit(params)

    return res.data.data
  },
  async deletePropertyById({ commit }, params) {
    const res = await this.$repositories.manager.deletePropertyById(params)

    return res.data.data
  },
  async updatePropertyById({ commit }, params) {
    const res = await this.$repositories.manager.updatePropertyById(params)

    return res.data.data
  },

  async getListTanent({ commit }, params) {
    const res = await this.$repositories.manager.getListTanent(params)

    return res.data
  },
  async getTanentById({ commit }, params) {
    const res = await this.$repositories.manager.getTanentById(params)

    return res.data
  },
  async deleteTanentById({ commit }, params) {
    const res = await this.$repositories.manager.deleteTanentById(params)

    return res.data.data
  },
  async addTanent({ commit }, params) {
    const res = await this.$repositories.manager.addTanent(params)

    return res.data.data
  },
  async updateTanent({ commit }, params) {
    const res = await this.$repositories.manager.updateTanent(params)

    return res.data.data
  },
  async searchForTenantByEmail({ commit }, params) {
    const res = await this.$repositories.manager.searchForTenantByEmail(params)

    return res.data.data
  },
  async getPropertyForTenant({ commit }, params) {
    const res = await this.$repositories.manager.getPropertyForTenant(params)

    return res.data.data
  },

  async getListOwners({ commit }, params) {
    const res = await this.$repositories.manager.getListOwners(params)

    return res.data
  },
  async getOwnerById({ commit }, params) {
    const res = await this.$repositories.manager.getOwnerById(params)

    return res.data.data
  },
  async deleteOwnerById({ commit }, params) {
    const res = await this.$repositories.manager.deleteOwnerById(params)

    return res.data.data
  },
  async addOwner({ commit }, params) {
    const res = await this.$repositories.manager.addOwner(params)

    return res.data.data
  },
  async updateOwner({ commit }, params) {
    const res = await this.$repositories.manager.updateOwner(params)

    return res.data.data
  },
  async getVendorList({ commit }, params) {
    const res = await this.$repositories.manager.getVendorList(params)

    return res.data
  },
  async addVendor({ commit }, params) {
    const res = await this.$repositories.manager.addVendor(params)

    return res.data
  },
  async getVendor({ commit }, params) {
    const res = await this.$repositories.manager.getVendor(params)

    return res.data
  },
  async deleteVendor({ commit }, params) {
    const res = await this.$repositories.manager.deleteVendor(params)

    return res.data
  },
  async updateVendor({ commit }, params) {
    const res = await this.$repositories.manager.updateVendor(params)

    return res.data
  },
  async getMaintenanceList({ commit }, params) {
    const res = await this.$repositories.manager.getMaintenanceList(params)

    return res.data
  },
  async getVendorListForMaintenance({ commit }, params) {
    const res = await this.$repositories.manager.getVendorListForMaintenance(
      params
    )

    return res.data
  },
  async addMaintenance({ commit }, params) {
    const res = await this.$repositories.manager.addMaintenance(params)

    return res.data
  },
  async updateMaintenance({ commit }, params) {
    const res = await this.$repositories.manager.updateMaintenance(params)

    return res.data
  },
  async completedMaintenance({ commit }, params) {
    const res = await this.$repositories.manager.completedMaintenance(params)

    return res.data
  },
  async notifyTenantsMaintenance({ commit }, params) {
    const res = await this.$repositories.manager.notifyTenantsMaintenance(
      params
    )

    return res.data
  },
  async getMaintenance({ commit }, params) {
    const res = await this.$repositories.manager.getMaintenance(params)

    return res.data
  },
  async deleteMaintenance({ commit }, params) {
    const res = await this.$repositories.manager.deleteMaintenance(params)

    return res.data
  },
  async previewMaintenance({ commit }, params) {
    const res = await this.$repositories.manager.previewMaintenance(params)

    return res.data
  },
  async addcontact({ commit }, params) {
    const res = await this.$repositories.manager.addcontact(params)

    return res.data
  },
  async updateContact({ commit }, params) {
    const res = await this.$repositories.manager.updateContact(params)

    return res.data
  },
  async getContactList({ commit }, params) {
    const res = await this.$repositories.manager.getContactList(params)

    return res.data
  },
  async getContact({ commit }, params) {
    const res = await this.$repositories.manager.getContact(params)

    return res.data
  },
  async deleteContact({ commit }, params) {
    const res = await this.$repositories.manager.deleteContact(params)
    return res.data
  },
  async sendRequestForAdmin({ commit }, params) {
    const res = await this.$repositories.manager.sendRequestForAdmin(params)
    return res.data
  },

  async addHolidayGreeting({ commit }, params) {
    const res = await this.$repositories.manager.addHolidayGreeting(params)
    return res.data
  },
  async getListOfHolidayGreetings({ commit }, params) {
    const res = await this.$repositories.manager.getListOfHolidayGreetings(
      params
    )
    return res.data
  },
  async deleteHolidayGreeting({ commit }, params) {
    const res = await this.$repositories.manager.deleteHolidayGreeting(params)
    return res.data
  },
  async getListOfHolidayGreetingsGallery({ commit }, params) {
    const res =
      await this.$repositories.manager.getListOfHolidayGreetingsGallery(params)
    return res.data
  },
  async resendCard({ commit }, params) {
    const res = await this.$repositories.manager.resendCard(params)
    return res.data
  },
  async getHolidayCategoryText({ commit }, params) {
    const res = await this.$repositories.manager.getHolidayCategoryText(params)
    return res.data
  },
  async getHolidayCardDetail({ commit }, params) {
    const res = await this.$repositories.manager.getHolidayCardDetail(params)
    return res.data
  },
  async updateCard({ commit }, params) {
    const res = await this.$repositories.manager.updateCard(params)
    return res.data
  },
  async getMediaLib({ commit }, params) {
    const res = await this.$repositories.manager.getMediaLib(params)
    return res.data
  },
  async deleteImageFromLib({ commit }, params) {
    const res = await this.$repositories.manager.deleteImageFromLib(params)
    return res.data
  },
  async getPropertyForReport({ commit }, params) {
    const res = await this.$repositories.manager.getPropertyForReport(params)
    return res.data
  },
  async getPropertyForReportSummary({ commit }, params) {
    const res = await this.$repositories.manager.getPropertyForReportSummary(
      params
    )
    return res.data
  },
  async addReport({ commit }, params) {
    const res = await this.$repositories.manager.addReport(params)
    return res.data
  },
  async previewReport({ commit }, params) {
    const res = await this.$repositories.manager.previewReport(params)
    return res.data
  },
  async getYearForReportByProperty({ commit }, params) {
    const res = await this.$repositories.manager.getYearForReportByProperty(
      params
    )
    return res.data
  },
  async getMonthForReportByProperty({ commit }, params) {
    const res = await this.$repositories.manager.getMonthForReportByProperty(
      params
    )
    return res.data
  },
  async getDocumentsData({ commit }, params) {
    const res = await this.$repositories.manager.getDocumentsData(params)
    return res.data
  },
  async getPropertiesSummary({ commit }, params) {
    const res = await this.$repositories.manager.getPropertiesSummary(params)
    return res.data
  },
  async getListOfApplicationsForProperty({ commit }, params) {
    const res = await this.$repositories.manager.getListOfApplicationsForProperty(params)
    return res.data
  },
  async getApplicationSummary({ commit }, params) {
    const res = await this.$repositories.manager.getApplicationSummary(params)
    return res.data
  },
  async approveApplications({ commit }, params) {
    const res = await this.$repositories.manager.approveApplications(params)
    return res.data
  },
  async declineApplications({ commit }, params) {
    const res = await this.$repositories.manager.declineApplications(params)
    return res.data
  },
  async setUpPaymentGateway({ commit }, params) {
    const res = await this.$repositories.manager.setUpPaymentGateway(params)
    return res.data
  },
  async getTemplates({ commit }, params) {
    const res = await this.$repositories.manager.getTemplates(params)
    return res.data
  },
  async getInquiryList({ commit }, params) {
    const res = await this.$repositories.manager.getInquiryList(params)
    return res.data
  },
  async markReadInquiry({ commit }, params) {
    const res = await this.$repositories.manager.markReadInquiry(params)
    return res.data
  },
  async deleteEnquiry({ commit }, params) {
    const res = await this.$repositories.manager.deleteEnquiry(params)
    return res.data
  },
  async getInquiryNumber({ commit }, params) {
    const res = await this.$repositories.manager.getInquiryNumber(params)
    return res.data
  },
  async getInquiryDates({ commit }, params) {
    const res = await this.$repositories.manager.getInquiryDates(params)
    return res.data
  },
  async getInquiryDates({ commit }, params) {
    const res = await this.$repositories.manager.getInquiryDates(params)
    return res.data
  },
  async clearAllInquiryDates({ commit }, params) {
    const res = await this.$repositories.manager.clearAllInquiryDates(params)
    return res.data
  },
}
export default actions
