
import { mapMutations, mapState, mapActions } from 'vuex'
export default {
  // props: {
  //   showBanner: {
  //     type: Boolean,
  //     default: true,
  //   },
  //   fixed: {
  //     type: Boolean,
  //     default: false,
  //   },
  //   showOwnersButton: {
  //     type: Boolean,
  //     default: true,
  //   },
  //   showHeaderNav: {
  //     type: Boolean,
  //     default: true,
  //   },
  // },
  data() {
    return {
      showMobileMenu: false,
      loginDialog: false,
      subdomainName: null,
      managerConfig: null,
    }
  },
  // watch: {
  //   isLogInDialogShowOpen() {
  //     this.loginDialog = this.isLogInDialogShowOpen
  //   },
  //   managerConfig() {
  //     this.bannerText =
  //       this.managerConfig[`bannerText${this.$vuetify.lang.current}`]
  //   },
  //   '$auth.loggedIn'() {
  //     this.checkUser()
  //   },
  // },
  computed: {
    ...mapState('taxonomy', ['taxonomy']),
    ...mapState('config/site', [
      'isBlur',
      'siteConfig',
      'isScheduleShowOpen',
      'isLogInDialogShowOpen',
    ]),
  },
  created() {
    this.resetHeaderState()
    // this.checkUser()
  },
  mounted() {
    const host = window.location.host
    const parts = host.split('.')
    console.log(parts)
    this.subdomainName = parts[0]
    if (this.subdomainName) {
      this.getSubDomainConfig()
    }
  },
  // destroyed(){
  //   console.log('destroyed')
  //   this.$vuetify.theme.themes.light.primary = this.siteConfig.primaryColor
  // },
  methods: {
    ...mapMutations('config/site', ['setLogInDialogShow','setErrorSnackbar']),
    ...mapActions('config/site', ['setTypeOfLoginwant']),
    ...mapActions('config/menu', ['resetHeaderState']),
    ...mapActions('subdomain', ['getConfig','sendPersonalWebsiteContact']),
    
    async getSubDomainConfig() {
      //   if (process.client) {
      //   this.$nuxt.$loading.start()
      // }
      this.getConfig({ subdomainName: this.subdomainName }).then(res=>{
       
        this.managerConfig = res.data
       
        console.log("this.managerConfig<<<<<<<<<<",this.managerConfig)
        if (this.managerConfig) {
          this.$vuetify.theme.themes.light.primary =
            this.managerConfig.primaryColor
          // console.log(this.$vuetify)
          if (process.client) {
            this.$nuxt.$loading.finish()
          }
        }
      })
     
    }
  },
}
