
import { mapActions, mapState } from 'vuex'
export default {
  props:{
    showFree:{
      type:Boolean,
      default:false
    }
  },
  data(){
      return{
          valid: false,
          obj: {
            languageID: 1,
            name: null,
            email: null,
            message: null,
            contactNumber: null,
            contactEmail:  null,
          },
          emailRules: [
            v => !!v || this.$t('E-mail is required'),
            v => /.+@.+\..+/.test(v) || this.$t('E-mail must be valid'),
          ],
          phoneRules: [v => !!v || this.$t('Phone Number is required')],
          
          phone:false,
          email:false,
          loading:false
      }
  },
  watch:{
    showFree(){
      if(this.showFree){
        this.obj.message=this.$i18n.locale=='en'?'Please send me the special code for a free month of ProperDee. And more information. I understand there is no obligation':'กรุณาส่งโค้ดพิเศษเพื่อรับ ProperDee ฟรี 1 เดือนให้ฉัน พร้อมข้อมูลเพิ่มเติม ฉันเข้าใจว่าไม่มีข้อผูกมัดใดๆ'
      }
    }
  },
  created(){
  if(this.showFree){
        this.obj.message=this.$i18n.locale=='en'?'Please send me the special code for a free month of ProperDee. And more information. I understand there is no obligation':'กรุณาส่งโค้ดพิเศษเพื่อรับ ProperDee ฟรี 1 เดือนให้ฉัน พร้อมข้อมูลเพิ่มเติม ฉันเข้าใจว่าไม่มีข้อผูกมัดใดๆ'
      }
  },
  computed: {
    ...mapState('taxonomy', ['taxonomy']),
  },
  methods:{
    ...mapActions('home',['sendAgentContact']),
    ...mapActions('config/site', ['scrollTop', 'setErrorSnackbar']),
    validate() {
    return this.$refs.agentCOntactForm.validate()
    },
    sendContactform(){
        this.loading=true
        if(this.validate()){
          this.obj.sendFreeActivation=this.showFree
            this.sendAgentContact(this.obj).then(data=>{
                this.setErrorSnackbar({
                    title: this.$t('Message Sent!'),
                    msg: this.$t('Message Sent!'),
                    color: this.$vuetify.theme.themes.light.success,
                })
                this.close()
                this.loading=false
            }).catch(err=>{
                this.setErrorSnackbar({
                    title: this.$t('Error!'),
                    msg: this.$t('Try again!'),
                    color: this.$vuetify.theme.themes.light.error,
                })
                this.loading=false
            })
        }else{
            this.loading=false
        }
        
    },
    close(){
        
        this.$refs.agentCOntactForm.reset()
        this.$emit('closePopupsContactFormHeader')
    }
  }
}
