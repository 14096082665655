
const actions = {
 
  async getFaq({ commit }) {
    const res = await this.$repositories.faq.getFaq()
    
    return res.data
  },
}
export default actions
