const actions = {
  resetHeaderState({ commit }, params) {
    commit('resetState', params)
  },
  setMobileMenu({ commit }, params) {
    commit('setMobileMenus', params)
  },
  setMobileLogo({ commit }, params) {
    commit('setMobileMenuLogo', params)
  },
  setMobileMenuHomeUrl({ commit }, params) {
    commit('setMobileMenuHomeUrl', params)
  },
  setMobileMenuManagementLoginBtn({ commit }, params) {
    commit('setMobileMenuManagementLoginBtn', params)
  },
}
export default actions
