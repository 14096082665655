const controller = '/property'
export default axios => ({
  getPropertyList(obj) {
    axios.defaults.headers.common['Authorization'] = null
    return axios.get(`${controller}/all-properties`, obj)
  },
  getPropertyDetails(obj) {
    axios.defaults.headers.common['Authorization'] = null
    return axios.get(`${controller}/get-properties/${obj.id}`, obj)
  },
  search(obj) {
    axios.defaults.headers.common['Authorization'] = null
    let parmas = `?itemsPerPage=${obj.pageInfo.itemsPerPage}&page=${obj.pageInfo.currentPage}`
    return axios.post(`${controller}/search${parmas}`, obj.obj)
  },
  applyForRent(obj) {
    return axios.post(`${controller}/apply-to-rent`, obj)
  },
  sendPropertyContact(obj) {
    axios.defaults.headers.common['Authorization'] = null
    return axios.post(`${controller}/get-properties/${obj.id}/contact`, obj)
  },
  sendAgentContact(obj) {
    axios.defaults.headers.common['Authorization'] = null
    return axios.post(`${controller}/agent-contact`, obj)
  },
})
