const mutations = {
  setPackages(state, packages) {
    
    state.packages=packages
    // console.log('state.taxonomy', state.taxonomy)
    
    // console.log('localStorage.getItem',localStorage.getItem('taxonomy'))
  },
 
}
export default mutations