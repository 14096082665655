const state = () => ({
    navMenus:[
      {
        url: '/home',
        name: 'Home',
      },
      
      {
        url: '/recommend',
        name: 'Recommend',
      },
      {
        url: '/pricing',
        name: 'Pricing',
      },
      {
        url: '/contact',
        name: 'Contact Us',
      },
    ],
    logo:'https://royalasia.blob.core.windows.net/default/ProperDee_transparent.png',
    homeUrl:'/home',
    showManagementLogin:false,
    isMobileMenuOpen: false,
})
export default state
