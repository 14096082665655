import siteConfig from '~/api/common/siteConfig'
import demo from '~/api/common/demo'
import getTaxonomy from '~/api/common/taxonomy'
import getPackages from '~/api/packages/packages'
import user from '~/api/user/index'
import auth from '~/api/auth/index'
import payment from '~/api/payment/index'
import media from '~/api/media/index'
import customizePersonalWebsite from '~/api/personal-website/index'
import translate from '~/api/translate/index'
import manager from '~/api/dashboard/manager'
import chat from '~/api/dashboard/chat'
import documents from '~/api/dashboard/documents'
import property from '~/api/home/property'
import home from '~/api/home/home'
import subdomain from '~/api/subdomain/index'
import adminDashboard from '~/api/admin/dashboard'
import faq from '~/api/common/faq'
import ai from '~/api/ai'

export default axios => ({
  demo: demo(axios),
  faq: faq(axios),
  siteConfig: siteConfig(axios),
  getTaxonomy: getTaxonomy(axios),
  getPackages: getPackages(axios),
  user: user(axios),
  auth: auth(axios),
  payment: payment(axios),
  media: media(axios),
  customizePersonalWebsite: customizePersonalWebsite(axios),
  translate: translate(axios),
  manager: manager(axios),
  chat: chat(axios),
  documents: documents(axios),
  property: property(axios),
  home: home(axios),
  subdomain: subdomain(axios),
  adminDashboard: adminDashboard(axios),
  ai: ai(axios),
})
