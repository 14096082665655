
import { mapMutations, mapState, mapActions } from 'vuex'
export default {
  model: {
    prop: 'showSchedule',
  },
  props: {
    title: {
      type: String,
      default: () => {
        return 'SCHEDULE A VISIT'
      },
    },
    heading: {
      type: String,
      default: () => {
        return 'I would like to schedule a showing of this property'
      },
    },
    imgSrc: {
      type: String,
      default: require('~/assets/img/cartoons/8-Tuk_Tuk.png'),
    },
    propertyId: {
      type: [Number,String],
      default: null,
    },
    maxWidth: {
      type: Number,
      default: 600,
    },
    imageWidth: {
      type: Number,
      default: 100,
    },
    imageHeight: {
      type: Number,
      default: 100,
    },
    putSpaceAround: {
      type: Boolean,
      default: false,
    },
    inital: {
      type: Boolean,
      default: false,
    },
    showSchedule: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showDialog: false,
      valid: false,
      dialog: this.showSchedule,
      timeArray: [
        '2.00 P.M',
        '3.00 P.M',
        '4.00 P.M',
        '5.00 P.M',
        '6.00 P.M',
        '7.00 P.M',
        '8.00 P.M',
        '9.00 P.M',
      ],
      phone: false,
      email: false,
      datePicker: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      time: '2.00 P.M',
      demo: {
        propertyId:this.$route.params.id,
        languageID: 1,
        demoTypeID: 1,
        name:this.$auth.user ? this.$auth.user.firstName : null,
        forDateTime: '',
        demoOrSchedule: false,
        contactNumber: this.$auth.user ? this.$auth.user.phoneNumber : null,
        contactEmail: this.$auth.user ? this.$auth.user.email : null,
      },
      emailRules: [
        v => !!v || this.$t('E-mail is required'),
        v => /.+@.+\..+/.test(v) || this.$t('E-mail must be valid'),
      ],
      phoneRules: [v => !!v || this.$t('Phone Number is required')],
      scheduleButtonClicked: false,
      successDetails: {
        title: 'Demo',
        img: 'demo-confirm.svg',
        description:
          'You have requested a Demo of Properdee in [ Language ]. We will send you an email confirmation shortly after we contact our Properdee team...',
      },
    }
  },
  computed: {
    ...mapState('taxonomy', ['taxonomy']),
  },
  created() {
    // console.log(this.$auth)
    if (this.$auth.user) {
      this.demo.contactNumber = this.$auth.user
        ? this.$auth.user.phoneNumber
        : null
      this.demo.contactEmail = this.$auth.user ? this.$auth.user.email : null
    }
  },
  watch: {
    showSchedule(val) {
      if (val !== this.dialog) {
        this.dialog = val
      }
    },
    dialog(val) {
      this.$emit('input', val)
      // this.setScheduleShow({ demo: false, visit: false })
    },
  },
  destroyed() {
    console.log('destroyed showSchedule')
  },
  methods: {
    ...mapMutations('config/site', ['setScheduleShow', 'setErrorSnackbar']),
    ...mapActions('adddemo', ['addDemo']),
    validate() {
      return this.$refs.shecduleForm.validate()
    },
    createDemoSchedule() {
      this.scheduleButtonClicked = true
      if (this.validate()) {
        this.demo.forDateTime = this.datePicker + ' ' + this.time
        if (this.title === 'SCHEDULE A VISIT') {
          this.demo.demoOrSchedule = true
        }
        console.log(this.demo)
        this.addDemo(this.demo).then(data => {
          if (data) {
            this.setErrorSnackbar({
              title: this.$t(
                this.title === 'SCHEDULE A VISIT'
                  ? 'Schedule Visit!'
                  : 'Demo SCHEDULE'
              ),
              msg: this.$t('Successful!'),
              color: this.$vuetify.theme.themes.light.success,
            })
          } else {
            this.validate()
            this.setErrorSnackbar({
              title: this.$t(
                this.title === 'SCHEDULE A VISIT'
                  ? 'Schedule Visit Failed!'
                  : 'Demo SCHEDULE Failed|'
              ),
              msg: this.$t('Failed!, Please Check form!'),
              color: this.$vuetify.theme.themes.light.error,
            })
          }
          setTimeout(() => {
            this.scheduleButtonClicked = true
            this.dialog = false
            this.successDetails = {
              title: this.title === 'SCHEDULE A VISIT' ? 'SCHEDULE' : 'Demo',
              img:
                this.title === 'SCHEDULE A VISIT'
                  ? 'schedule-confirm.svg'
                  : 'demo-confirm.svg',
              description:
                this.title === 'SCHEDULE A VISIT'
                  ? `You have requested a Property Showing ${
                      this.taxonomy.demoType.filter(
                        el => el.id === this.demo.demoTypeID
                      )[0].name
                    }. We will send you an email confirmation as soon as we contact our Property Management team.`
                  : `You have requested a Demo of Properdee in ${
                      this.taxonomy.language.filter(
                        el => el.id === this.demo.languageID
                      )[0].name
                    } language We will send you an email confirmation shortly after we contact our Properdee team...`,
            }
            console.log(this.title)
            console.log(this.successDetails)
            this.$refs.shecduleForm.reset()
            this.showDialog = true
            this.scheduleButtonClicked = false
          }, 500)
        })
      } else {
        this.scheduleButtonClicked = false
      }
    },
  },
}
