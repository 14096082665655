const getters = {
  currencySymbol: state => {
    switch (state.currency) {
      case 1:
        return 'USD'
      case 2:
        return 'THB'
      default:
        return 'THB'
    }
  },
}
export default getters
