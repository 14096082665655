const controller = '/manager/dashboard/document'
export default axios => ({
  getDocumentsList(obj){
    let parmas = `?itemsPerPage=${obj.itemsPerPage}&page=${obj.page}`
    if (obj.search) {
      parmas = parmas + `&search=${obj.search}`
    }
    return axios.get(`${controller}${parmas}`)
  },
  deleteDocument(obj){
    
    return axios.delete(`${controller}/${obj.id}`)
  },
  createLeaseDocument(obj) {
    return axios.post(`${controller}/create-lease-agreement-pdf`, obj)
  },
  editLeaseDocument(obj) {
    return axios.put(`${controller}/create-lease-agreement-pdf`, obj)
  },
  getLeaseData(obj){
    return axios.get(`${controller}/edit-document/${obj.id}`, obj)
  }
})
