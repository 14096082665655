const actions = {
  
 async sendEmail({ commit }, params) {
    const response = await this.$repositories.generic.communication.sendEmail({
      
        "EmailTo": params.email,
        "Template": params.template,
        "Information": [
            params.information
        ]
      
    })
    
    // if(response.data.DbQueryStatus[0].STATUS){
    //   commit('setUserInfo',response.data.DbQueryData.Table[0])
    // }
      console.log('mediaUpload',response.data)
    return response.data.ApiStatus && response.data.DbQueryStatus[0].status
  },

}
export default actions
