
import { mapActions, mapState, } from 'vuex'
export default {
    async fetch(){
        if (process.client) {
        this.$nuxt.$loading.start()
        }

        const obj = {
            page: this.pageNumber,
            itemsPerPage: this.resultSet,
            propertyListingType:this.propertyListingType,
            date:this.date
        }
        if (this.search) {
            obj.search = this.search
        }
        const resp = await this.getInquiryList(obj)
        if(resp){

            this.list=resp.data
            this.resultSet = resp.pageInfo.itemsPerPage
            this.pageNumber = resp.pageInfo.currentPage
             if (process.client) {
                this.$nuxt.$loading.finish()
            }
        }
    },
    props:{
        date:{
            type:String,
            default:null
        },
        rentOrBuy:{
            type:Number,
            default:162
        },
        showClearAll:{
            type:Boolean,
            default:false
        }
    },
    data(){
        return{
            girdOrlist:1,
            seletedInquiry:null,
            resultSet: 10,
            pageNumber: 1,
            search:null,
            list:[],
            propertyListingType:this.rentOrBuy,
        }
    },
     computed: {
        ...mapState('taxonomy', ['taxonomy']),


    },
    watch:{
        propertyListingType(){
            this.$fetch()

        },
        date(){
            this.$fetch()
        }
    },
    methods:{
        ...mapActions('manager',['getInquiryList','markReadInquiry','deleteEnquiry']),
         async deletedItem(id) {
            if (await this.confirmDelete()) {
                console.log(id)
                if (await this.confirmagain())
                this.deleteEnquiry({ id: id }).then(data => {
                    if (data) {
                    this.$fetch()
                    }
                })
            }
            },
        async confirmDelete() {
            const option = {
                cancelText: this.$t('No'),
                confirmText: this.$t('Yes'),
                width: 800,
            }
            return await this.$refs.confirm.open(
                this.$t('Confirm'),
                this.$t('Are you Sure?'),
                option
            )
        },
        async confirmagain() {
            const option = {
                cancelText: this.$t('No'),
                confirmText: this.$t('Yes'),
                width: 800,
            }
            return await this.$refs.confirmagain.open(
                this.$t('Confirm'),
                this.$t('Last Chance - Are you Sure you want to Delete this Forever?'),
                option
            )
        },
    }
}
