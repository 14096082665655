const actions = {
  async mediaUpload({ commit }, params) {
    const response = await this.$repositories.media.addMedia(params)
    return response.data
  },
  async addMultipleMedia({ commit }, params) {
    const response = await this.$repositories.media.addMultipleMedia(params)
    return response.data
  },
  async deleteMedia({ commit }, params) {
    const response = await this.$repositories.media.deleteMedia(params)
    return response.data
  },
}
export default actions
