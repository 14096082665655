
import {mapState} from 'vuex'
import DefaultLayout from './default.vue'
export default {
  extends: DefaultLayout,
  data() {
    return {
      clipped: true,
      drawer: false,
      fixed: false,
      items: [
        {
          icon: 'mdi-view-dashboard',
          title: this.$t('Dashboard'),
          to: this.localeRoute('/dashboard/manager/main'),
          badgeNumber: 0,
        },
        {
          icon: 'mdi-image-filter-hdr',
          title: this.$t('Properties'),
          to: this.localeRoute('/dashboard/manager/properties'),
          badgeNumber: 0,
        },
        {
          icon: 'mdi-phone',
          title: this.$t('Contacts'),
          to: this.localeRoute('/dashboard/manager/contacts'),
          badgeNumber: 0,
        },
        {
          icon: 'mdi-file',
          title: this.$t('Documents'),
          to: this.localeRoute('/dashboard/manager/documents'),
          badgeNumber: 0,
        },
         {
          icon: 'mdi-folder-wrench',
          title: this.$t('My Website'),
          to: this.localeRoute('/dashboard/manager/my-website'),
          badgeNumber: 0,
        },
        {
          icon: 'mdi-wallet-giftcard',
          title: this.$t('e-Card Greetings'),
          to: this.localeRoute('/dashboard/manager/holiday-greetings'),
          badgeNumber: 0,
        },
       
        {
          icon: 'mdi-file-document-outline',
          title: this.$t('Business Cards'),
          to: this.localeRoute('/dashboard/manager/business-card'),
          badgeNumber: 0,
        },
         {
          icon: 'mdi-lock-outline',
          title: this.$t('Templates'),
          to: this.localeRoute('/dashboard/manager/templates'),
          badgeNumber: 0,
        },
         {
          icon: 'mdi-post',
          title: this.$t('Blog/Alerts'),
          to: this.localeRoute('/dashboard/manager/properdee-alerts'),
          badgeNumber: 0,
        },
         {
          icon: 'mdi-domain',
          title: this.$t('Want to Rent'),
          desc:'rent',
          to: this.localeRoute('/dashboard/manager/inquiry?propertyListingType=162'),
          badgeNumber: 0,
        },
         {
          icon: 'mdi-tag-outline',
          title: this.$t('Want to Buy'),
          desc:'buy',
          to: this.localeRoute('/dashboard/manager/inquiry?propertyListingType=161'),
          badgeNumber: 0,
        },
        {
          icon: 'mdi-cog',
          title: this.$t('General Settings'),
          to: this.localeRoute('/dashboard/manager/general-settings'),
          badgeNumber: 0,
        },
      ],
      items2: [
        
        {
          icon: 'mdi-calculator',
          title: this.$t('ProperDeeAgents'),
          to: '/',
        },
       
        
        {
          icon: 'mdi-file-document-outline',
          title: this.$t('Personal Website'),
          to: this.$i18n.locale=='en'?this.$auth.user.subDomain+'/en'+ '/manager':this.$auth.user.subDomain+ '/manager',
        },
        // {
        //   icon: 'mdi-lock-outline',
        //   title: this.$t('Insurance'),
        //   to: this.localeRoute('/dashboard/manager/insurance'),
        // },
       
        
      ],
      miniVariant: false,
      right: true,
      rightDrawer: false,
      title: this.$t('Vuetify.js'),
      type: this.$route.params.type,
      timestamp: null,
      showInquiry:false,
      rentOrBuy:162
    }
  },

  watch: {
    '$route.path': {
      handler(val) {
        console.log('this.$route.path', this.$route.path)
        this.setNavMenu()
      },
      deep: true,
    },
  },
  computed: {
    ...mapState('config/site', ['siteConfig']),
  },
  created() {
    this.setNavMenu()
    setInterval(() => {
      this.getNow()
    }, 1000)
    console.log(this.$auth.user.package.subscription)
    if(this.$auth.user.package.proOrStarter){
      this.items.splice(4,0, {
          icon: 'mdi-format-list-bulleted',
          title: this.$t('Marketing'),
          to: this.localeRoute('/dashboard/manager/marketing'),
          badgeNumber: 0,
        },)
    }
  },
  mounted(){
      if (!this.$auth.user.subDomain && this.$auth.user.package.valid) {
              console.log('set subdomain ===>', this.$auth.user.package.paymentUrl)
              this.showSubDomainName = true
        } else if(this.$auth.user.subDomain && this.$auth.user.package.valid) {
          console.log('All set ===>', this.$auth.user.package.paymentUrl)
          this.showSubDomainName = false
        }else if(this.$auth.user.package.paymentUrl){
          console.log('redirect user', this.$auth.user.package.paymentUrl)
          this.$router.push(this.localePath(this.$auth.user.package.paymentUrl))
        }
  },
  methods: {
    showInquiryDialog(ev){
      console.log("ev",ev)
      this.showInquiry=true
      this.rentOrBuy=ev==='rent'?162:161
    },
    logout() {
      this.$router.push(this.localeRoute('/logout-callback'))
    },
    setNavMenu() {
      console.log('setNavMenu', this.$route)
      if (this.type === 'tenant') {
        this.items = [
          {
            icon: 'mdi-view-dashboard',
            title: 'Dashboard',
            to: this.localeRoute('/dashboard/tenant/main'),
            badgeNumber: 0,
          },
          {
            icon: 'mdi-home-city-outline',
            title: 'Apply to Rent',
            to: this.localeRoute('/dashboard/tenant/apply-to-rent'),
            badgeNumber: 0,
          },
          {
            icon: 'mdi-file',
            title: 'Documents',
            to: this.localeRoute('/dashboard/tenant/documents'),
            badgeNumber: 0,
          },
          {
            icon: 'mdi-calculator',
            title: 'Accounting',
            to: this.localeRoute('/dashboard/tenant/accounting'),
            badgeNumber: 0,
          },
          {
            icon: 'mdi-message-reply-text',
            title: 'Messages',
            to: this.localeRoute('/dashboard/tenant/message'),
            badgeNumber: 0,
          },
          {
            icon: 'mdi-hammer-wrench',
            title: 'Maintenance',
            to: this.localeRoute('/dashboard/tenant/maintenance'),
            badgeNumber: 0,
          },
          {
            icon: 'mdi-credit-card-wireless-outline',
            title: 'Pay Rent Online',
            to: this.localeRoute('/dashboard/tenant/pay-rent-online'),
            badgeNumber: 0,
          },
          {
            icon: 'mdi-lock-outline',
            title: this.$t('Insurance'),
            to: this.localeRoute('/dashboard/tenant/insurance'),
            badgeNumber: 0,
          },
          {
            icon: 'mdi-cog',
            title: 'General Settings',
            to: this.localeRoute('/dashboard/tenant/general-settings'),
            badgeNumber: 0,
          },
        ]
        this.items2 = []
      }
      if (this.type === 'owner') {
        this.items = [
          {
            icon: 'mdi-view-dashboard',
            title: 'Dashboard',
            to: this.localeRoute('/dashboard/owner/main'),
            badgeNumber: 0,
          },
          {
            icon: 'mdi-file-document-outline',
            title: 'Report',
            to: this.localeRoute('/dashboard/owner/Report'),
            badgeNumber: 0,
          },
          {
            icon: 'mdi-file',
            title: 'Documents',
            to: this.localeRoute('/dashboard/owner/documents'),
            badgeNumber: 0,
          },
          {
            icon: 'mdi-calculator',
            title: 'Accounting',
            to: this.localeRoute('/dashboard/owner/accounting'),
            badgeNumber: 0,
          },
          {
            icon: 'mdi-account-multiple',
            title: 'Tenant(s)',
            to: this.localeRoute('/dashboard/owner/tenants'),
            badgeNumber: 0,
          },
          {
            icon: 'mdi-message-reply-text',
            title: 'Messages',
            to: this.localeRoute('/dashboard/owner/message'),
            badgeNumber: 0,
          },

          // {
          //   icon: 'mdi-credit-card-wireless-outline',
          //   title: 'Pay Bill Online',
          //   to: this.localeRoute('/dashboard/owner/pay-bill-online'),
          //   badgeNumber: 0,
          // },
          {
            icon: 'mdi-lock-outline',
            title: this.$t('Insurance'),
            to: this.localeRoute('/dashboard/owner/insurance'),
            badgeNumber: 0,
          },
          {
            icon: 'mdi-cog',
            title: 'General Settings',
            to: this.localeRoute('/dashboard/owner/general-settings'),
            badgeNumber: 0,
          },
        ]
        this.items2 = []
      }
      if (this.type === 'admin') {
        this.items = [
          {
            icon: 'mdi-view-dashboard',
            title: 'Dashboard',
            to: this.localeRoute('/dashboard/admin/main'),
            badgeNumber: 0,
          },
          
          {
            icon: 'mdi-table-account',
            title: 'All Subscribers',
            to: this.localeRoute(
              '/dashboard/admin/subscribers-list'
            ),
            badgeNumber: 0,
          },
          {
            icon: 'mdi-home-group-plus',
            title: 'All Properties',
            to: this.localeRoute('/dashboard/admin/properties'),
            badgeNumber: 0,
          },
          {
            icon: 'mdi-alpha-i-box',
            title: 'All Inquiry ',
            to: this.localeRoute('/dashboard/admin/inquiry'),
            badgeNumber: 0,
          },
          
          
          {
            icon: 'mdi-video',
            title: 'All Videos',
            to: this.localeRoute('/dashboard/admin/videos'),
            badgeNumber: 0,
          },
          {
            icon: 'mdi-file-document-multiple-outline',
            title: 'All Documents',
            to: this.localeRoute('/dashboard/admin/documents'),
            badgeNumber: 0,
          },
          {
            icon: 'mdi-text-box-outline',
            title: 'All template',
            to: this.localeRoute('/dashboard/admin/templates'),
            badgeNumber: 0,
          },
          {
            icon: 'mdi-account-group-outline',
            title: 'All User',
            to: this.localeRoute('/dashboard/admin/user?type=0&name=All User'),
            badgeNumber: 0,
          },
          {
            icon: 'mdi-credit-card-outline',
            title: 'Payment History',
            to: this.localeRoute('/dashboard/admin/payment-history'),
            badgeNumber: 0,
          },
          {
            icon: 'mdi-email-outline',
            title: 'Popups',
            to: this.localeRoute('/dashboard/admin/emails-popups'),
            badgeNumber: 0,
          },

          {
            icon: 'mdi-flower-poppy',
            title: 'Properdee Alerts',
            to: '/dashboard/admin/ProperdeeAlerts',
            badgeNumber: 0,
          },

          {
            icon: 'mdi-account-multiple-plus-outline',
            title: 'Admin List',
            to: this.localeRoute(
              '/dashboard/admin/user?type=1&name=Admin List'
            ),
            badgeNumber: 0,
          },

          {
            icon: 'mdi-percent-circle-outline',
            title: 'Discount Lists',
            to: this.localeRoute('/dashboard/admin/discount-lists'),
            badgeNumber: 0,
          },
         
          {
            icon: 'mdi-finance',
            title: 'Analytics',
            to: this.localeRoute('/dashboard/admin/analytics'),
            badgeNumber: 0,
          },
          {
            icon: 'mdi-cake-variant-outline',
            title: 'e-Card Greetings Lists',
            to: this.localeRoute('/dashboard/admin/holiday-greetings-lists'),
            badgeNumber: 0,
          },
          {
            icon: 'mdi-cog-outline',
            title: 'General Settings',
            to: this.localeRoute('/dashboard/admin/general-settings'),
            badgeNumber: 0,
          },
        ]
        this.items2 = []
      }
      if (this.type === 'bahtsold') {
        this.items = [
          {
            icon: 'mdi-view-dashboard',
            title: this.$t('Dashboard'),
            to: this.localeRoute('/dashboard/bahtsold/main'),
            badgeNumber: 0,
          },
          {
          icon: 'mdi-image-filter-hdr',
          title: this.$t('Properties'),
          to: this.localeRoute('/dashboard/bahtsold/properties'),
          badgeNumber: 0,
        },
        {
          icon: 'mdi-phone',
          title: this.$t('Contacts'),
          to: this.localeRoute('/dashboard/bahtsold/main'),
          badgeNumber: 0,
        },
        {
          icon: 'mdi-file',
          title: this.$t('Documents'),
          to:  this.localeRoute('/dashboard/bahtsold/main'),
          badgeNumber: 0,
        },
         {
          icon: 'mdi-folder-wrench',
          title: this.$t('My Website'),
          to:  this.localeRoute('/dashboard/bahtsold/main'),
          badgeNumber: 0,
        },
        {
          icon: 'mdi-wallet-giftcard',
          title: this.$t('e-Card Greetings'),
          to:  this.localeRoute('/dashboard/bahtsold/main'),
          badgeNumber: 0,
        },
       
        {
          icon: 'mdi-file-document-outline',
          title: this.$t('Business Cards'),
          to:  this.localeRoute('/dashboard/bahtsold/main'),
          badgeNumber: 0,
        },
         {
          icon: 'mdi-lock-outline',
          title: this.$t('Templates'),
          to:  this.localeRoute('/dashboard/bahtsold/main'),
          badgeNumber: 0,
        },
        {
          icon: 'mdi-cog',
          title: this.$t('General Settings'),
          to:  this.localeRoute('/dashboard/bahtsold/main'),
          badgeNumber: 0,
        },
        ]
        this.items2 = [
           {
            icon: 'mdi-calculator',
            title: this.$t('ProperDee Home'),
            to: 'https://properdee.com',
          },
        ]
      }
    },
    getNow: function () {
      const today = new Date()
      const time =
        today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds()
      const dateTime = time
      this.timestamp = dateTime
    },
  },
}
