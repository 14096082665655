const controller = '/payment'
export default axios => ({
  getPaymentMethods(obj) {
    let parmas = obj.addons?`?addon=${obj.addons}`:''
    return axios.get(`${controller}/${obj.packageId}${parmas}`)
  },
  updatePaymentMethods(obj) {
    return axios.put(`${controller}/${obj.paymentExternalId}`, {
      paymentMethod: obj.paymentMethod,
      bank: obj.bank,
    })
  },
  addDiscountCode(obj) {
    return axios.put(`${controller}/${obj.paymentExternalId}/discount`, {
      discountCode: obj.discountCode,
    })
  },
  pay(obj) {
    return axios.post(`${controller}/${obj.paymentExternalId}/pay`, {
      card: obj.card,
    })
  },
  createPayPalOrder(obj) {
    return axios.post(
      `${controller}/${obj.paymentExternalId}/paypal/order/create`
    )
  },
  getMyCreditCards(obj) {
    return axios.post(`${controller}/${obj.paymentExternalId}/pay`)
  },
  paymentStatus(obj) {
    return axios.post(`${controller}/status`, {
      token: obj.token,
    })
  },
  freePayment(obj) {
    return axios.post(`${controller}/free-package`)
  },
})
