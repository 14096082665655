
export default {
  model: {
    prop: 'dialog',
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    scrollable: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    maxWidth: {
      type: [String, Number],
      default: '460px',
    },
    minHeight: {
      type: [String, Number],
      default: '660px',
    },
    noTopBar: {
      type: Boolean,
      default: false,
    },
    contentClass: {
      type: String,
      default: '',
    },
    cardColor: {
      type: String,
      default: '',
    },
    persistent: {
      type: Boolean,
      default: true,
    },
    eager: {
      type: Boolean,
      default: true,
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
  },
  destroyed() {
    console.log('destroyed')
  },
  methods: {
    handleClickOut(ev) {
      if (!this.persistent) {
        this.$emit('input', false)
      }
    },
  },
}
