
import { mapState, mapActions } from 'vuex'
export default {
    props:{
        rentOrBuy:{
            type:[Number, String],
            default:161
        }
    },
    data(){
        return{
            seletedInquiry:null,
            inquiryObj:{
            "propertyListingType": this.rentOrBuy,
            "area": null,
            "city": null,
            "propertyTypeId": [
            ],
            "minPrice": 0,
            "maxPrice": 0
            },
            min: 10000,
            max: 2000000,
            range: [-20, 70],
            resultSet: 10,
            pageNumber: 1,
            pages: 0,
            list:[],
            
        }
    },
    computed: {
        ...mapState('taxonomy', ['taxonomy']),
    },
    methods:{
        ...mapActions('manager',['getInquiryList']),
        search(){
            console.log("this.pageNumber",this.pageNumber)
            console.log("this.resultSet",this.resultSet)
            this.inquiryObj.page=this.pageNumber
            this.inquiryObj.itemsPerPage=this.resultSet
            this.inquiryObj.minPrice=this.range[0]
            this.inquiryObj.maxPrice=this.range[1]
               
                this.inquiryObj.search = null
            this.getInquiryList(this.inquiryObj).then(resp=>{
                this.list=resp.data
                this.resultSet = resp.pageInfo.itemsPerPage
                this.pageNumber = resp.pageInfo.currentPage
                this.pages = resp.pageInfo.pages
                this.totalListingNumber= resp.pageInfo.itemsTotal
            })
        },
        setPage(ev){
            this.pageNumber=ev
            this.$fetch()
        },
    }
}
