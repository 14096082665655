
export default {
  name: 'DefaultNotifications',
  props: {
    showAlerts: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    notifications: [
      'Mike John Responded to your email',
      'You have 5 new tasks',
      "You're now friends with Andrew",
      'Another Notification',
      'Another one',
    ],
  }),
}
